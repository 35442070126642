<!--
 * @Description: 商城订单 已卖宝贝
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:43:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-05-31 12:44:22
-->
<template>
  <el-card class="box">
    <el-tabs v-model="tabActive" @tab-click="tabClick">
      <el-tab-pane label="全部" name></el-tab-pane>
      <el-tab-pane
        :label="item.text"
        :name="item.value"
        v-for="item in enums.mall_order_status"
        :key="item.value"
      ></el-tab-pane>
    </el-tabs>
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="订单号">
        <el-input v-model="query.q" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item label="收件人">
        <el-input v-model="query.consignee" placeholder="收件人姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机人手机号">
        <el-input v-model="query.mobile_phone" placeholder="请输入完整手机号"></el-input>
      </el-form-item>
      <el-form-item label="快递单号">
        <el-input v-model="query.courier_no" placeholder="请输入完整快递单号"></el-input>
      </el-form-item>
      <el-form-item label="下单日期">
        <el-date-picker
          v-model="query.date_list"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
  </el-card>

  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column label="订单号" fixed="left" width="200">
        <template #default="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="onDtl(scope.row)"
          >{{ scope.row.order_no }}</el-link>
        </template>
      </el-table-column>

      <!-- <el-table-column label="商品图" width="140">
        <template #default="scope">
          <el-image :src="scope.row.goods_logo_url" style="width: 80px; height: 80px" fit="fill"></el-image>
        </template>
      </el-table-column>-->

      <el-table-column label="商品标题" width="240" prop="goods_title"></el-table-column>
      <el-table-column label="规格" prop="sku_text" width="150"></el-table-column>
      <el-table-column label="数量" width="100">
        <template #default="scope">x{{ scope.row.number }}</template>
      </el-table-column>
      <el-table-column label="金额" width="160">
        <template #default="scope">
          <div>商品总额：￥{{ scope.row.decimal_amount }}</div>
          <div>订单总额：￥{{ scope.row.decimal_total_amount }}</div>
          <div>运费金额：￥{{ scope.row.decimal_shipping_amount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" width="120" prop="status_text"></el-table-column>
      <el-table-column label="支付状态" width="180">
        <template #default="scope">
          <div>状态：{{ scope.row.pay_status_text }}</div>
          <div>时间：{{ scope.row.pay_at }}</div>
        </template>
      </el-table-column>
      <el-table-column label="客户" prop="member_name" width="140"></el-table-column>
      <el-table-column label="物流" width="240">
        <template #default="scope">
          <div>收货人：{{ scope.row.consignee }}</div>
          <div>手机号：{{ scope.row.consignee_mobile_phone }}</div>
          <div>地址：{{ scope.row.consignee_address }}</div>
        </template>
      </el-table-column>
      <el-table-column label="物流" width="220">
        <template #default="scope">
          <div v-if="scope.row.waybill_no">
            <span>{{scope.row.kd100_company_name}}、{{scope.row.waybill_no}}</span>
            <div>
              <waybill :order="scope.row"></waybill>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="180" prop="create_at"></el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <deliver
            v-if="!scope.row.waybill_no && scope.row.status==10"
            :order="scope.row"
            :logistics="logistic_list"
          ></deliver>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <order-dtl :id="current_id" @close="current_id=null;"></order-dtl>
</template>
<script>
import common_api from "@/http/common_api";
import Deliver from "./order_deliver.vue";
import Waybill from "./order_waybill.vue";
import OrderDtl from "./order_dtl.vue";

export default {
  components: {
    Deliver,
    Waybill,
    OrderDtl,
  },
  data() {
    return {
      loading: false,
      tabActive: "",
      enums: {},
      //物流公司
      logistic_list: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //数据
      tableData: {
        counts: 0,
      },
      //
      current_id: null,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    this.loadLogistics();
    this.loadData();
  },
  methods: {
    /**
     * tab点击
     */
    tabClick(tab) {
      console.log(tab.props);
      this.query.status = tab.props.name;
      this.onSearch();
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },

    /**
     * 加载物流公司
     */
    loadLogistics() {
      this.$http.get("seller/v1/logistics").then((res) => {
        if (res.code == 0) {
          this.logistic_list = res.data;
        }
      });
    },

    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/mallOrder", this.query).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 展开订单详情
     * @param {*} item
     */
    onDtl(item) {
      console.log(item);
        this.current_id = item.id;
    },
  },
};
</script>

<style  scoped>
</style>