<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-08-31 05:07:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-01 02:27:01
-->

<template>
  <el-button type="primary" link size="small" @click="onOpen">发货、拆单发货</el-button>
  <el-dialog
    title="发货"
    v-model="visibleDialog"
    width="800px"
    :close-on-press-escape="false"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="物流公司" prop="company_id">
        <el-select v-model="form.company_id" placeholder>
          <el-option :label="item.name" :value="item.id" v-for="item in logistics" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="快递单号" prop="waybill_no">
        <el-col :span="14" :offset="0">
          <el-input v-model="form.waybill_no" placeholder></el-input>
        </el-col>
      </el-form-item>
    </el-form>
    <el-divider>快递子单</el-divider>
    <el-table :data="children_list">
      <el-table-column label>
        <template #header>
          <el-button size="small" type="primary" link icon="plus" @click="addChildren">添加</el-button>
        </template>
        <template #default="scope">
          <el-select v-model="scope.row.company_id" placeholder="快递公司">
            <el-option :label="item.name" :value="item.id" v-for="item in logistics" :key="item.id"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label>
        <template #default="scope">
          <el-input v-model="scope.row.waybill_no" placeholder="快递单号"></el-input>
        </template>
      </el-table-column>
      <el-table-column label width="80">
        <template #default="scope">
          <el-button type="danger" link icon="remove" @click="onRemove(scope.$index)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visibleDialog: false,
      saving: false,
      form: {},
      rules: {
        company_id: [{ required: true, message: "必选", trigger: "blur" }],
        waybill_no: [{ required: true, message: "必填", trigger: "blur" }],
      },
      //子单
      children_list: [],
    };
  },
  props: ["order", "logistics"],
  emits: ["close"],
  methods: {
    /**
     *
     */
    onOpen() {
      this.visibleDialog = true;
    },
    /**
     * 添加子单
     */
    addChildren() {
      this.children_list.push({});
    },
    /**
     * 移除
     */
    onRemove(i) {
      this.children_list.splice(i, 1);
    },
    /**
     * 提交
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          var model = {
            id: this.order.id,
            waybill_list: [
              {
                company_id: this.form.company_id,
                waybill_no: this.form.waybill_no,
              },
            ],
          };
          if (this.children_list.length > 0) {
            this.children_list.forEach((x) => {
              if (x.company_id && x.waybill_no) {
                model.waybill_list.push({
                  company_id: x.company_id,
                  waybill_no: x.waybill_no,
                });
              }
            });
          }
          this.$http
            .post("seller/v1/mallOrder/deliver", model)
            .then((res) => {
              if (res.code == 0) {
                (this.visibleDialog = false), this.$emit("close", true);
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>>